import Image from '@global/components/atoms/Image';
import i18n from '@i18n/index';
import kemiLogo from '@static/media/kemi-logo.svg';
import { styled } from '@styles/stitches.config';

type LandingLogoProps = {
  onClick?: () => void;
};

const LandingLogo = ({ onClick }: LandingLogoProps) => {
  return (
    <KemiLogo onClick={onClick}>
      <Image src={kemiLogo} alt={'Kemi'} />
      <KemiLogoTitle>{i18n.t('k_kemi')}</KemiLogoTitle>
    </KemiLogo>
  );
};

const KemiLogo = styled('div', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

const KemiLogoTitle = styled('span', {
  display: 'block',
  fontType: 'subtitleBold',
  marginLeft: 5,
});

export default LandingLogo;
