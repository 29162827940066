import _ from 'lodash';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { imageFileToBase64, selectFiles } from '../dom';

import KemiRuntimeError from '@global/service/Error/KemiRuntimeError';

export type QueryType = {
  readonly response: any;
  readonly variables: any;
};

export const useSafeLayoutEffect =
  typeof window === 'undefined' ? useEffect : useLayoutEffect;

export const useForm = <T extends Record<string, any>>(
  initialState: T,
  validator?: (state: T) => boolean
) => {
  const [state, setState] = useState(initialState);

  const handleValueChange = <T extends keyof typeof state>(key: T) => {
    return (value: typeof state[T]) => {
      setState({
        ...state,
        [key]: value,
      });
    };
  };

  const isValid = validator ? validator(state) : true;

  return {
    setFormState: <T extends keyof typeof state>(
      key: T,
      value: typeof state[T]
    ) => handleValueChange(key)(value),
    setFormStateBindKey: handleValueChange,
    setState,
    formState: { ...state },
    isValid,
    isStateChanged: (key: keyof typeof state) =>
      !_.isEqual(initialState[key], state[key]),
  };
};

type UseUploadProps = {
  uploader: (file: File) => Promise<{ imageUrl: string }>;
  validator?: (
    file: File
  ) => string[] | Promise<string[]> | Promise<undefined> | undefined;
};

export const useUpload = ({ uploader, validator }: UseUploadProps) => {
  const [state, setState] = useState<{
    file?: File;
    error?: string[] | undefined;
  }>({});

  const select = async (options?: Parameters<typeof selectFiles>[0]) => {
    const files = await selectFiles(options);

    if (!files) {
      setState({});
      return;
    }

    const file = files[0];

    if (validator) {
      const error = await validator(file);

      if (error) {
        setState({ ...state, error });
        return error;
      }
    }

    setState({ file, error: undefined });
    return file;
  };

  const upload = async (customFile?: File) => {
    const file = customFile || state.file;

    if (!file) {
      throw new KemiRuntimeError({
        code: 'INVALID_PARAMETER',
        message: 'no file selected',
        options: { file: state.file },
      });
    }

    const result = await uploader(file);

    return result;
  };

  return {
    select,
    upload,
    file: state.file,
    error: state.error,
  };
};

type UseImageUploadProps = {
  uploader: (file: File) => Promise<{ imageUrl: string }>;
  validator?: (
    file: File
  ) => string[] | Promise<string[]> | Promise<undefined> | undefined;
};

export const useImageUpload = ({
  uploader,
  validator,
}: UseImageUploadProps) => {
  const [imageDataUrl, setImageDataUrl] = useState<string | undefined>();
  const { select, upload, error } = useUpload({ uploader, validator });

  const _select = async () => {
    const maybeFile = await select({ accept: 'image/*' });

    if (!(maybeFile instanceof File)) {
      return maybeFile;
    }

    const imageDataUrl = await imageFileToBase64(maybeFile); // base64 URL

    setImageDataUrl(imageDataUrl);
    return maybeFile;
  };

  return {
    select: _select,
    upload,
    error,
    imageDataUrl,
  };
};

export const useRatio = <T extends HTMLElement>(
  ratio: string | number,
  diff?: number,
  dep: any[] = []
) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (!ref.current) return;

    const changeHeightByRatio = () => {
      if (!ref.current) return;

      const nodeWidth = ref.current.getClientRects()[0].width;

      let newHeight: number = 0;

      if (typeof ratio === 'string') {
        const [width, height] = ratio.split('/');

        if (!width || !height) {
          throw new KemiRuntimeError({
            code: 'INVALID_PARAMETER',
            message:
              'Invalid ratio format. Please confirm and edit. ex) "16/9" ',
          });
        }

        newHeight = (nodeWidth * Number(height)) / Number(width) + (diff || 0);
      } else if (typeof ratio === 'number') {
        newHeight = nodeWidth * ratio + (diff || 0);
      }

      ref.current.style.height = `${newHeight}px`;
    };

    changeHeightByRatio();

    window.addEventListener('resize', changeHeightByRatio);

    return () => {
      window.removeEventListener('resize', changeHeightByRatio);
    };
  }, [diff, ratio, ...dep]);

  return ref;
};
