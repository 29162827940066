import { SVGAttributes } from 'react';

type ArrowRightProps = SVGAttributes<SVGSVGElement> & {
  color?: string;
  inverse?: boolean;
  disabled?: boolean;
};

const ArrowRight = ({ color = '#303030', ...rest }: ArrowRightProps) => {
  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={'M14 6L20 12L14 18'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M20 12H4'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default ArrowRight;
