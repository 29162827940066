import { useRouter } from 'next/router';

import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import LandingButton from '@pages/landing/shared/components/LandingButton';
import ROUTE from '@routers';
import { styled } from '@styles/stitches.config';
import { getUserAgent } from '@utils/dom';

const FAQ = () => {
  const router = useRouter();

  const eventTag = {
    [ROUTE.index]: {
      faqClick: EVENT_TAG.LANDING.LD_HOME_FAQ_CLICK,
      contactClick: EVENT_TAG.LANDING.LD_HOME_CONTACT_CLICK,
    },
    [ROUTE.creator]: {
      faqClick: EVENT_TAG.LANDING.LD_CREATOR_FAQ_CLICK,
      contactClick: EVENT_TAG.LANDING.LD_CREATOR_CONTACT_CLICK,
    },
    [ROUTE.seller]: {
      faqClick: EVENT_TAG.LANDING.LD_SELLER_FAQ_CLICK,
      contactClick: EVENT_TAG.LANDING.LD_SELLER_CONTACT_CLICK,
    },
  }[router.pathname];

  const handleClickGoToFAQ = () => {
    logFirebase(UserInteractionType.CLICK, eventTag.faqClick, {
      device: getUserAgent().device.type,
    });
  };

  const handleClickContactToManager = () => {
    logFirebase(UserInteractionType.CLICK, eventTag.contactClick, {
      device: getUserAgent().device.type,
    });
  };

  return (
    <Container>
      <Content>
        <Title>{i18n.t('k_landing_shared_more_questions')}</Title>
        <Buttons>
          <LandingButton
            text={i18n.t('k_go_to_FAQ')}
            theme={'black'}
            onClick={handleClickGoToFAQ}
            linkTo={'https://kemi.oopy.io/'}
          />
          <LandingButton
            text={i18n.t('k_contact_to_manager')}
            theme={'black'}
            onClick={handleClickContactToManager}
            linkTo={'https://kemi.channel.io/support-bots/40446'}
          />
        </Buttons>
      </Content>
    </Container>
  );
};

const Container = styled('div', {
  marginTop: 240,
  marginBottom: 120,
  padding: '64px 24px',
  borderRadius: 40,
  background: 'linear-gradient(180deg, #E6BEFF 0%, #BEDCFF 100%)',
});

const Content = styled('div', {
  width: '100%',
  maxWidth: 320,
  margin: '0 auto',
});

const Title = styled('h2', {
  fontType: 'heading3',
  marginBottom: 24,
});

const Buttons = styled('div', {
  display: 'grid',
  gap: 8,
});

export default FAQ;
