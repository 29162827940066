import { getUserAgent } from '@utils/dom';

export const getLandingLogData = () => {
  const ua = getUserAgent();

  return {
    date: new Date(),
    device: ua.device.type ?? 'desktop',
    OS: ua.os.name,
    OS_ver: ua.os.version,
  };
};
