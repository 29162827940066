import { useRouter } from 'next/router';

import { KEMI_BLOG_URL } from '../constant';
import { getLandingLogData } from '../helper';

import LandingLogo from './LandingLogo';
import SideMenuModal from './SideMenuModal';

import Button from '@global/components/atoms/Button';
import { EVENT_TAG } from '@global/constants';
import useModal from '@global/hooks/useModal';
import { logFirebase } from '@global/service/logger/EventHandler';
import useLoginAndSignupModalStore from '@global/store/loginAndSignupModalStore';
import { LoginModalType, UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import ROUTE from '@routers';
import SideMenuIcon from '@static/svg/Menu';
import { styled } from '@styles/stitches.config';

const Header = () => {
  const router = useRouter();
  const sideMenuModal = useModal();
  const eventTag = {
    [ROUTE.index]: {
      signIn: EVENT_TAG.LANDING.LD_HOME_SIGNIN_CLICK,
      signUp: EVENT_TAG.LANDING.LD_HOME_SIGNUP_CLICK,
    },
    [ROUTE.creator]: {
      signIn: EVENT_TAG.LANDING.LD_CREATOR_SIGNIN_CLICK,
      signUp: EVENT_TAG.LANDING.LD_CREATOR_SIGNUP_CLICK,
    },
    [ROUTE.seller]: {
      signIn: EVENT_TAG.LANDING.LD_SELLER_SIGNIN_CLICK,
      signUp: EVENT_TAG.LANDING.LD_CREATOR_SIGNUP_CLICK,
    },
  }[router.pathname];
  const MENU = [
    { title: i18n.t('k_home'), route: ROUTE.index },
    { title: i18n.t('k_creator'), route: ROUTE.creator },
    { title: i18n.t('k_seller'), route: ROUTE.seller },
  ];

  const handleSelectMenu = (route: string) => {
    if (route === KEMI_BLOG_URL) {
      logFirebase(UserInteractionType.CLICK, EVENT_TAG.LANDING.LD_BLOG_CLICK);
    }

    router.replace(route);
  };

  const { open } = useLoginAndSignupModalStore();

  const handleClickLogin = () => {
    logFirebase(
      UserInteractionType.CLICK,
      eventTag.signIn,
      getLandingLogData()
    );

    open({ type: LoginModalType.login, redirectTo: router.asPath });
  };

  const handleClickSignUp = () => {
    logFirebase(
      UserInteractionType.CLICK,
      eventTag.signUp,
      getLandingLogData()
    );

    open({ type: LoginModalType.signup, redirectTo: router.asPath });
  };

  const handleClickSideMenu = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.LANDING.LD_MO_SIDEMENU_CLICK
    );

    sideMenuModal.open();
  };

  return (
    <>
      <Container>
        <LandingLogo />
        <Navigator>
          {MENU.map((currentMenu, index) => (
            <Menu
              key={index}
              onClick={() => handleSelectMenu(currentMenu.route)}
              selected={MENU[index].route === router.pathname}
            >
              <span>{currentMenu.title}</span>
            </Menu>
          ))}
        </Navigator>
        <LoginAndSignUp>
          <AuthButton type={'line'} onClick={handleClickLogin}>
            {i18n.t('k_log_in')}
          </AuthButton>
          <AuthButton onClick={handleClickSignUp}>
            {i18n.t('k_register')}
          </AuthButton>
        </LoginAndSignUp>
        <SideMenu>
          <SideMenuIcon onClick={handleClickSideMenu} />
        </SideMenu>
      </Container>
      <SideMenuModal
        isOpen={sideMenuModal.openState}
        onClose={sideMenuModal.close}
      />
    </>
  );
};

const Container = styled('div', {
  position: 'sticky',
  left: 0,
  width: '100%',
  background: 'rgba(255, 255, 255, 0.72)',
  backdropFlter: 'blur(32px)',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px 28px 20px 33px',
  zIndex: '$contentHeader',
  paddingTop: 'calc(20px + 16px)',
  top: '-16px',

  '@landingTablet': {
    padding: 16,
    paddingTop: 'calc(16px + 16px)',
  },
});

const Navigator = styled('div', {
  display: 'none',

  '@landingTablet': {
    display: 'flex',
    gap: 16,
  },
});

const Menu = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 16px',
  borderRadius: 24,

  '&:hover': {
    cursor: 'pointer',
  },
  '& > span': {
    fontType: 'bodyRegular',
    display: 'block',
  },
  variants: {
    selected: {
      true: {
        backgroundColor: '$black',
        color: '$white',
      },
    },
  },
});

const LoginAndSignUp = styled('div', {
  display: 'flex',
  gap: 8,
});

const AuthButton = styled(Button, {
  whiteSpace: 'nowrap',
  height: '40px !important',
});

const SideMenu = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '@landingTablet': {
    display: 'none',
  },
});

export default Header;
