import { useState } from 'react';

import LinkNameInput from '../../source/components/LinkNameInput';

import i18n from '@i18n/index';
import { styled } from '@styles/stitches.config';

const ReadyToStart = () => {
  const [linkName, setLinkName] = useState('');

  return (
    <Container>
      <Title>{i18n.t('k_are_you_ready_to_start_kemi')}</Title>
      <Descriptions>
        <Description>
          <Icon>{'🌟'}</Icon>
          <span>{i18n.t('k_landing_shared_start_kemi_message_1')}</span>
        </Description>
        <Description>
          <Icon>{'💳'}</Icon>
          <span>{i18n.t('k_landing_shared_start_kemi_message_2')}</span>
        </Description>
        <Description>
          <Icon>{'🚚'}</Icon>
          <span>{i18n.t('k_landing_shared_start_kemi_message_3')}</span>
        </Description>
      </Descriptions>
      <LinkNameInput
        linkName={linkName}
        onChange={setLinkName}
        validTextColor={'grey100'}
      />
    </Container>
  );
};

const Container = styled('div', {
  width: '100%',
  paddingX: 24,
  margin: '120px auto 0 auto',

  '@landingTablet': {
    width: '400px',
  },
});

const Title = styled('h2', {
  fontType: 'heading3',
  marginBottom: 24,
  whiteSpace: 'pre-line',
});

const Descriptions = styled('div', {
  display: 'grid',
  gap: 8,
  marginBottom: 24,
});

const Description = styled('p', {
  display: 'flex',
  fontType: 'bodyRegular',
  whiteSpace: 'pre-line',
});

const Icon = styled('span', {
  display: 'block',
  marginRight: 8,
});

export default ReadyToStart;
