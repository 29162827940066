const Menu = ({ ...rest }) => {
  const stroke = rest.color || '#303030';
  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={'M7 6H17'}
        stroke={stroke}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M4 12H20'}
        stroke={stroke}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M7 18H17'}
        stroke={stroke}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default Menu;
