import { Fragment, useMemo } from 'react';

import i18n from '@i18n/index';
import ROUTE from '@routers';
import { css, styled } from '@styles/stitches.config';

type Props = {
  transparent?: boolean;
};

function Footer({ transparent }: Props) {
  const BUSINESS_INFO = useMemo(
    () => [
      { label: i18n.t('k_ceo'), value: i18n.t('k_ceo_value') },
      { label: i18n.t('k_corporate_register_number'), value: '631-87-01013' },
      { label: i18n.t('k_online_business'), value: '2018-서울강남-02902' },
      {
        label: i18n.t('k_business_address'),
        value: i18n.t('k_business_address_value'),
      },
      { label: i18n.t('k_business_tel_number'), value: '1533-2909' },
    ],
    []
  );
  return (
    <Block transparent={transparent}>
      <span className={itemCss({ subTitle: true })}>
        {i18n.t('k_wiredcompany')}
      </span>
      <Content>
        {BUSINESS_INFO.map(({ label, value }) => (
          <Fragment key={value}>
            <span className={itemCss()}>{label}</span>
            <span className={itemCss()}>{value}</span>
          </Fragment>
        ))}
      </Content>
      <Terms>
        <a href={ROUTE.terms} target={'_blank'} rel={'noreferrer'}>
          <span className={itemCss({ pointer: true })}>
            {i18n.t('k_terms_of_use')}
          </span>
        </a>
        <span className={itemCss({ divider: true })}>{'|'}</span>
        <a href={ROUTE.privacy} target={'_blank'} rel={'noreferrer'}>
          <span className={itemCss({ pointer: true })}>
            {i18n.t('k_privacy_policy')}
          </span>
        </a>
      </Terms>
    </Block>
  );
}

const Block = styled('div', {
  background: '$white',
  variants: {
    transparent: {
      true: {
        background: 'none',
      },
    },
  },
});

const Content = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gridTemplateColumns: '80px 1fr',
  gap: 8,
  marginTop: 16,
});

const Terms = styled('div', {
  display: 'inline-grid',
  gridAutoFlow: 'column',
  gap: 8,
  marginTop: 16,
});

const itemCss = css({
  fontType: 'captionRegular',
  color: '$grey100',
  variants: {
    subTitle: {
      true: {
        fontType: 'subtitleBold',
      },
    },
    pointer: {
      true: {
        cursor: 'pointer',
      },
    },
    divider: {
      true: {
        color: '$grey50',
      },
    },
  },
});

export default Footer;
