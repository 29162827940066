import { useRouter } from 'next/router';
import React from 'react';

import { KEMI_BLOG_URL } from '../constant';

import LandingLogo from './LandingLogo';

import Button from '@global/components/atoms/Button';
import Modal from '@global/components/atoms/Modal';
import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import useLoginAndSignupModalStore from '@global/store/loginAndSignupModalStore';
import { LoginModalType, UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import ROUTE from '@routers';
import Close from '@static/svg/Close';
import { styled } from '@styles/stitches.config';
import { useSafeLayoutEffect } from '@utils/hook/custom-hook';

type SideMenuModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SideMenuModal = ({ isOpen, onClose }: SideMenuModalProps) => {
  const router = useRouter();
  const { open } = useLoginAndSignupModalStore();

  const MENU = [
    { title: i18n.t('k_home'), route: ROUTE.index },
    { title: i18n.t('k_creator'), route: ROUTE.creator },
    { title: i18n.t('k_seller'), route: ROUTE.seller },
  ];

  const handleClickLogo = () => {
    router.replace(ROUTE.index);
    onClose();
  };

  const handleClickMenu = (route: string) => {
    if (route === KEMI_BLOG_URL) {
      logFirebase(UserInteractionType.CLICK, EVENT_TAG.LANDING.LD_BLOG_CLICK);
    }

    router.replace(route);
    onClose();
  };

  const handleClickLogin = () => {
    open({ type: LoginModalType.login, redirectTo: router.asPath });
    onClose();
  };

  const handleClickSignUp = () => {
    open({ type: LoginModalType.signup, redirectTo: router.asPath });
    onClose();
  };

  // 모바일 safari의 경우, url 입력영역으로 Modal이 잘려보여서 100vh가 아닌
  // 실제 innerHeight를 height로 지정해주었습니다.
  // ref: https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
  useSafeLayoutEffect(() => {
    document.documentElement.style.setProperty(
      '--app-height',
      `${window.innerHeight}px`
    );
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {({ onClose }) => (
        <Container>
          <Header>
            <LandingLogo onClick={handleClickLogo} />
            <CloseIcon
              onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                onClose(e, true)
              }
            />
          </Header>
          <Menus>
            {MENU.map((currentMenu, index) => (
              <Menu
                key={index}
                onClick={() => handleClickMenu(currentMenu.route)}
              >
                <span>{currentMenu.title}</span>
              </Menu>
            ))}
          </Menus>
          <Buttons>
            <Button type={'line'} onClick={handleClickLogin}>
              {i18n.t('k_log_in')}
            </Button>
            <Button onClick={handleClickSignUp}>{i18n.t('k_sign_up')}</Button>
          </Buttons>
        </Container>
      )}
    </Modal>
  );
};

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '$white',
  height: '100vh',
  padding: 16,
  maxHeight: 'var(--app-height)',
  width: '100%',
});

const Header = styled('div', {
  padding: 18,
  display: 'flex',
  justifyContent: 'space-between',
});

const CloseIcon = styled(Close, {
  cursor: 'pointer',
});

const Menus = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 40,
  flex: 1,
});

const Menu = styled('h2', {
  fontType: 'heading3',
  cursor: 'pointer',
});

const Buttons = styled('div', {
  display: 'grid',
  gap: 8,
});

export default SideMenuModal;
