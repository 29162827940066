import { useState, useCallback } from 'react';

export default function useModal(initialState: boolean = false) {
  const [openState, setOpenState] = useState(initialState);
  const open = useCallback(() => setOpenState(true), []);
  const close = useCallback(() => setOpenState(false), []);

  return {
    openState,
    open,
    close,
  };
}
