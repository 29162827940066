export const KEMI_BLOG_URL = 'https://kemi.oopy.io/kemi_blog/kr';

export const LANDING_IMAGE_URL_MAIN =
  'https://asset.kemi.io/images/landing/main';

export const LANDING_IMAGE_URL_CREATOR =
  'https://asset.kemi.io/images/landing/creator';

export const LANDING_IMAGE_URL_SELLER =
  'https://asset.kemi.io/images/landing/seller';

export const LANDING_IMAGE_URL_SHARED =
  'https://asset.kemi.io/images/landing/shared';
